@import "../../../constants.scss";

.file-picker {
    margin-bottom: 10px;
    &_preview {
        position: relative;
        // width: 120px;
        margin: 10px 0;
        display: flex;
        align-items: center;
        .clear {
            margin-left: 20px;
            background: $color-background-light;
            padding: 12px 36px;
            cursor: pointer;
            display: flex;
            align-items: center;
            border-radius: 4px;
            i {
                margin-right: 4px;
                // display: inline-block;s
            }
        }
        img {
            width: 120px;
        }
    }
    .label {
        margin-bottom: 10px;
        color: $color-text;
        font-size: 12px;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        input {
            margin-top: 10px;
        }
    }
}
