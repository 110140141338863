@import "../../constants.scss";
.loading {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    i {
        font-size: 3em;
        color: $color-primary;
    }
    p {
        font-size: 12px;
        text-transform: uppercase;
        color: $color-primary;
        opacity: 0.5;
    }
}
