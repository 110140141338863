@import "../../constants.scss";

.login-view {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    .container {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .card {
        width: 90%;
        max-width: 400px;
    }
    input {
        background-color: $color-background;
    }
}
