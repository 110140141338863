@import "../../../constants.scss";

.input {
    // width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    label {
        margin-bottom: 10px;
        color: $color-text;
        font-size: 12px;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
    }
    input {
        box-sizing: border-box;
        flex: 1 auto;

        margin-top: 10px;
        padding: 15px;
        background-color: $color-background-light;
        border: 0px;
        border-radius: 4px;
        outline: none;
        color: $color-text;
    }
    &-buttons {
        display: flex;
        margin: 10px 0;
    }
    &-button {
        background-color: $color-background-light;
        cursor: pointer;
        padding: 12px 36px;
        border-radius: 4px;
        &:last-child {
            margin-left: 10px;
        }
        &_active {
            background-color: $color-primary;
        }
    }
}
