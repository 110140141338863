$color-primary: #d77802;
$color-background: #1c1e22;
$color-background-light: #2b2f34;
$color-text: #fff;
$color-white: #fff;
$color-secondary: #fff;
$color-gray: #222427;

@mixin for-phone-only {
    @media (max-width: 599px) {
        @content;
    }
}

@mixin for-tablet-under {
    @media (max-width: 800px) {
        @content;
    }
}

@mixin for-tablet-portrait-up {
    @media (min-width: 600px) {
        @content;
    }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) {
        @content;
    }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) {
        @content;
    }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}
