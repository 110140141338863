@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700&display=swap");
@import "./constants.scss";

body {
    padding: 0;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: $color-background;
    #root {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
}

h1 {
    font-size: 3em;
    margin: 0;
    color: $color-primary;
}

.subtitle {
    color: $color-text;
    opacity: 0.5;
    max-width: 500px;
    font-size: 1em;
    font-weight: 200;
}

h3 {
    margin: 0 0 20px;
    font-weight: 200;
    color: $color-primary;
    &::after {
        content: "";
        display: block;
        width: 50px;
        height: 2px;
        margin-top: 5px;
        background: $color-background-light;
    }
}

.container {
    max-width: 1080px;
    width: 95%;
    margin: 0 auto;
    display: block;
}

.card {
    background: $color-background-light;
    border-radius: 4px;
    padding: 17px;
}
