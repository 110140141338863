@import "../../../constants.scss";
.button {
    border: 0px;
    background: $color-primary;
    padding: 12px 24px;
    vertical-align: middle;
    color: $color-white;
    border-radius: 4px;
    font-size: 14px;
    // font-weight: bold;
    cursor: pointer;
    opacity: 1;
    transition: 0.5s ease opacity;
    display: flex;
    align-items: center;
    outline: none;
    &-loading {
        padding: 12px 24px !important;
    }

    &:hover {
        opacity: 0.8;
    }

    &-has-icon {
        padding: 12px 24px;
    }
    .icon {
        margin-right: 6px;
    }

    &-white {
        background: $color-white;
        color: #000;
    }

    &-transparent {
        background: transparent;
        color: $color-primary;
    }

    &-gray {
        background: $color-gray;
        color: #000;
    }
    &-disabled {
        background: $color-gray;
    }
}
