@import "../../constants.scss";
.survey-view {
    margin-top: 100px;
    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
            width: 120px;
        }
    }
    &_form {
        margin: 40px 0;
        .row {
            display: grid;
            grid-template-columns: 40% 20% 40%;
            @include for-phone-only {
                display: block;
            }
            .input {
                margin-right: 10px;
                &:last-child {
                    margin: 0;
                }
                @include for-phone-only {
                    margin-right: 0;
                }
            }
        }
    }
    .error {
        background: $color-background-light;
        padding: 15px;
        color: $color-primary;
        margin: 40px 0;
        p {
            margin: 0;
        }
    }
}
