@import "../../constants.scss";

.dashboard-view {
    .container {
        margin-top: 100px;
    }
    table {
        margin-top: 40px;
        background-color: $color-background-light;
        width: 100%;
        td {
            padding: 15px 15px;
            color: $color-text;
        }
        thead {
            td {
                color: $color-primary;
                font-weight: bold;
            }
        }
    }
}
