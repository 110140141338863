@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700&display=swap);
body{padding:0;margin:0;font-family:"Montserrat", sans-serif;display:flex;flex-direction:column;min-height:100vh;background-color:#1c1e22}body #root{flex:1 1;display:flex;flex-direction:column}h1{font-size:3em;margin:0;color:#d77802}.subtitle{color:#fff;opacity:0.5;max-width:500px;font-size:1em;font-weight:200}h3{margin:0 0 20px;font-weight:200;color:#d77802}h3::after{content:"";display:block;width:50px;height:2px;margin-top:5px;background:#2b2f34}.container{max-width:1080px;width:95%;margin:0 auto;display:block}.card{background:#2b2f34;border-radius:4px;padding:17px}

.survey-view{margin-top:100px}.survey-view-header{display:flex;justify-content:space-between;align-items:center}.survey-view-header .logo{width:120px}.survey-view_form{margin:40px 0}.survey-view_form .row{display:grid;grid-template-columns:40% 20% 40%}@media (max-width: 599px){.survey-view_form .row{display:block}}.survey-view_form .row .input{margin-right:10px}.survey-view_form .row .input:last-child{margin:0}@media (max-width: 599px){.survey-view_form .row .input{margin-right:0}}.survey-view .error{background:#2b2f34;padding:15px;color:#d77802;margin:40px 0}.survey-view .error p{margin:0}

.input{display:flex;flex-direction:column;margin-bottom:10px}.input label{margin-bottom:10px;color:#fff;font-size:12px;text-transform:uppercase;display:flex;flex-direction:column}.input input{box-sizing:border-box;flex:1 1 auto;margin-top:10px;padding:15px;background-color:#2b2f34;border:0px;border-radius:4px;outline:none;color:#fff}.input-buttons{display:flex;margin:10px 0}.input-button{background-color:#2b2f34;cursor:pointer;padding:12px 36px;border-radius:4px}.input-button:last-child{margin-left:10px}.input-button_active{background-color:#d77802}

.button{border:0px;background:#d77802;padding:12px 24px;vertical-align:middle;color:#fff;border-radius:4px;font-size:14px;cursor:pointer;opacity:1;-webkit-transition:0.5s ease opacity;transition:0.5s ease opacity;display:flex;align-items:center;outline:none}.button-loading{padding:12px 24px !important}.button:hover{opacity:0.8}.button-has-icon{padding:12px 24px}.button .icon{margin-right:6px}.button-white{background:#fff;color:#000}.button-transparent{background:transparent;color:#d77802}.button-gray{background:#222427;color:#000}.button-disabled{background:#222427}

.loading{flex:1 1;display:flex;justify-content:center;align-items:center;flex-direction:column;height:100%}.loading i{font-size:3em;color:#d77802}.loading p{font-size:12px;text-transform:uppercase;color:#d77802;opacity:0.5}

.file-picker{margin-bottom:10px}.file-picker_preview{position:relative;margin:10px 0;display:flex;align-items:center}.file-picker_preview .clear{margin-left:20px;background:#2b2f34;padding:12px 36px;cursor:pointer;display:flex;align-items:center;border-radius:4px}.file-picker_preview .clear i{margin-right:4px}.file-picker_preview img{width:120px}.file-picker .label{margin-bottom:10px;color:#fff;font-size:12px;text-transform:uppercase;display:flex;flex-direction:column}.file-picker .label input{margin-top:10px}

.login-view{height:100%;display:flex;justify-content:center;align-items:center;flex-direction:column;flex:1 1}.login-view .container{display:flex;flex:1 1;justify-content:center;align-items:center;flex-direction:column}.login-view .card{width:90%;max-width:400px}.login-view input{background-color:#1c1e22}

.dashboard-view .container{margin-top:100px}.dashboard-view table{margin-top:40px;background-color:#2b2f34;width:100%}.dashboard-view table td{padding:15px 15px;color:#fff}.dashboard-view table thead td{color:#d77802;font-weight:bold}

